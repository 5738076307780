.padded {
  padding-left: $base-padding;
  padding-right: $base-padding;
}

.unpadded {
  padding-left: 0;
  padding-right: 0;
}

// .left {
//   float: left;
// }
//
// .right {
//   float: right;
// }

.news-listing {

  .listing-item-col {
    padding-bottom: rem(100px);

    @include container {
      flex-basis: 50%;
      max-width: 50%;
    }

    @include phone {
      flex-basis: 100%;
      max-width: 100%;
    }

    .listing-item {
      background-color: $light-grey;
      position: relative;
      height: 100%;
      padding-bottom: rem(20px);

      .listing-image {
        @include full-background;
        @include responsive-ratio(330, 230, true);
      }

      .text-container {
        padding: rem(25px) rem(20px);
      }

      h5 {
        font-weight: 300;
        font-size: rem(14px);
        color: $red;
      }

      h4 {
        font-size: rem(20px);
      }

      p {
        font-weight: 300;
        font-size: rem(14px);
      }

      .btn-link {
        position: absolute;
        bottom: rem(-25px);
        right: 0;
        margin: 0;
      }
    }
  }
}

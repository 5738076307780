.floating-boxes {
  padding: 0;

  &.grey {
    position: relative;

    &:before {
      @include pseudo;
      left: 0;
      top: 0;
      bottom: 0;
      right: calc((100vw - #{$container-max-width-px}) / 2);
      background-color: $light-grey;

      @include container {
        right: 0;
      }
    }
  }

  .container {
    display: flex;
    flex: 0 1 auto;
    justify-content: flex-end;

    @include phone {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .red-box {
    position: absolute;
    font-size: rem(42.5px);
    color: $white;
    top: rem(-50px);
    right: 55%;
    width: calc(45% - #{$container-padding-h});
    // background-color: $red;
    background-image: url('../images/RedBackground.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: rem(40px) rem(35px) rem(45px);

    @include container {
      font-size: rem(36px);
      width: auto;
    }

    @include phone {
      position: static;
    }
  }

  .image-box {
    @include full-background;
    position: absolute;
    top: 42%;
    right: 55%;
    width: rem(300px);
    height: rem(300px);

    @include phone {
      position: static;
      width: 80vw;
      height: 80vw;
      margin-top: rem(-30px);
    }
  }

  .text-box {
    flex-basis: 55%;
    max-width: 55%;
    padding: rem(120px) rem(30px) rem(100px) rem(50px);

    @include phone {
      flex-basis: 100%;
      max-width: 100%;
      padding: rem(60px) 0 rem(60px);
    }

    h2 {
      font-size: rem(14px);
      line-height: 1;
      margin-bottom: rem(20px);
      text-transform: uppercase;
    }

    .subheading {
      font-size: rem(40px);
      line-height: 1.3;
      margin-bottom: rem(20px);
    }

    .paragraphs {
      font-weight: 300;
      font-size: rem(18px);

      p:last-child {
        margin-bottom: rem(20px);
      }
    }

    .btn-link {
      margin-right: rem(25px);

      // &:not(:first-of-type) {
      //
      //   @include lg-tablet {
      //      margin-left: rem(25px);
      //   }
      // }
    }
  }
}

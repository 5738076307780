.partners {

  h2 {
    text-align: center;
    font-size: rem(40px);
  }

  p {
    text-align: center;
  }

  .partner-slides {
    padding: 0 rem(60px);

    .slick-track {
      display: flex;

      .slick-slide {
        height: 100%;

        > div {
          height: 100%;
        }

        .slide {
          padding: 0 rem(15px);
          display: flex;
          align-items: stretch;
          height: 100%;

          @include phone {
            padding: 0 0 rem(40px) rem(45px);
          }
        }
      }
    }
  }

  .slick-prev {
    left: 0;
    // transform: translateY(rem(-29px));
  }

  .slick-next {
    right: 0;
    // transform: translateY(rem(29px));
  }
}

.off-white {
  background-color: $off-white;
}

header {
  position: relative;
}

.container {
  @extend %container;

  .container-xs {
    @include container-xs {
      padding: 0;
    }
  }
}

.container-xs {
  @extend %container;
  @extend %container-xs;
}

.container-sm {
  @extend %container;
  @extend %container-sm;

  .container-xs {
    @include container-xs {
      padding: 0;
    }
  }
}

.row {
  @extend %row;
}

.row-no-gutter {
  @extend %row;
  @extend %row-no-gutter;

  > %col {
    padding: 0;
  }
}

.row-lg-gutter {
  @extend %row;
  @extend %row-lg-gutter;

  > %col {
    padding: 0 ($grid-gutter * 3);
  }
}

.row-wrap {
  @extend %row;
  @extend %row-wrap;
}

.col {
  @extend %col;
}

.col-1 {
  @extend %col;
  @extend %col-1;
}

.col-2 {
  @extend %col;
  @extend %col-2;
}

.col-3 {
  @extend %col;
  @extend %col-3;
}

.col-4 {
  @extend %col;
  @extend %col-4;
}

.col-5 {
  @extend %col;
  @extend %col-5;
}

.col-6 {
  @extend %col;
  @extend %col-6;
}

.col-7 {
  @extend %col;
  @extend %col-7;
}

.col-8 {
  @extend %col;
  @extend %col-8;
}

.col-12 {
  @extend %col;
  @extend %col-12;
}

.h100 {
  height: 100%;
}

.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.7);
  z-index: 90;
  pointer-events: none;
  opacity: 0;
  transition: $base-transition;
}

body {
  padding-top: $nav-bar-height;

  &.nav-open,
  &.video-lightbox-show {
    .page-overlay {
      pointer-events: auto;
      opacity: 1;
    }
  }
}

.spacer {
  height: $base-spacing * 2;
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

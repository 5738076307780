.text-body {

  h2 {
    font-size: rem(40px);
  }

  h4 {
    font-weight: 600;
    font-size: rem(26px);

    &.blue {
      color: $dark-blue;
    }
  }

  .paragraphs {

    b,
    strong {
      font-weight: 600;
    }

    p {
      font-weight: 300;
      font-size: rem(18px);
    }

    a,
    a:visited {
      font-weight: 300;
      font-size: rem(18px);
      color: $red;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .link-container {
    display: inline-flex;
    flex-direction: column;
  }
}

.paragraphs {

  b,
  strong {
    font-weight: 600;
  }
}

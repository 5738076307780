.results {
  padding-bottom: rem(42px);

  .results-number {
    font-family: $font-hind;
    font-weight: 700;
    margin-top: rem(16px);
  }

  .result {
    font-family: $font-hind;
    font-weight: 300;
    margin-top: rem(20px);

    h5 {
      margin: 0;
    }
  }
}

nav {
  background-color: $off-white;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  height: $nav-bar-height;
  // border-bottom: rem(3px) solid $black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: $base-transition;

  // @include phone {
  //   background-color: $black;
  // }

  // &:after {
  //   @include pseudo;
  //   top: $nav-bar-toggle-width;
  //   left: 0;
  //   right: 0;
  //   height: rem(3px);
  //   background-color: $black;
  //   z-index: 10;
  // }

  &.nav-up {
    position: fixed;
    top: 0 - $nav-bar-height;
  }

  &.nav-down {
    position: fixed;
    top: 0;
  }

  .nav-home-link {
    z-index: 110;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 rem(35px) 0 rem(15px);
    font-family: $font-hind;
    font-weight: normal;
    // background-color: $black;
    color: $white;
    font-size: rem(22px);
    line-height: 0.9;
    width: rem((150 + 35 + 15));

    // .utc {
    //   font-size: rem(30px);
    // }
  }

  .nav-toggle {
    // position: absolute;
    // right: rem(20px);
    z-index: 10;
    // background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $nav-bar-toggle-width;
    // height: rem(26px);

    .nav-toggle-icon {
      position: relative;
      display: inline-block;
      width: rem(32px);
      height: rem(2px);
      background-color: $black;
      transition: $base-transition;

      &:before,
      &:after {
        @include pseudo;
        left: 0;
        width: rem(32px);
        height: rem(2px);
        background-color: $black;
        transition: $base-transition;
        z-index: 1;
      }

      &:before {
        top: rem(-10px);
      }

      &:after {
        bottom: rem(-10px);
      }
    }
  }

  .nav-left {
    padding-left: rem(10px);

    // @include tablet-max {
    //   display: none!important;
    // }

    .nav-link {
      position: relative;

      // &:after {
      //   @include pseudo;
      //   bottom: 0;
      //   left: rem(20px);
      //   right: rem(20px);
      //   height: rem(10px);
      //   background-color: $black;
      //   opacity: 0;
      //   transition: $base-transition;
      // }

      &.active,
      &:hover,
      &:focus {

        &:after {
          opacity: 1;
        }
      }
    }
  }

  .nav-right {
    margin-left: auto;

    // @include tablet-max {
    //   display: none!important;
    // }
  }

  .nav-left,
  .nav-right {
    // background-color: $white;
    // position: fixed;
    // padding-top: $nav-bar-height;
    // top: 0;
    // right: 0 - $nav-menu-width;
    // bottom: 0;
    // width: $nav-menu-width;
    transition: $base-transition;
    z-index: 5;
    display: inline-flex;
    align-items: stretch;
    justify-content: center;

    // @include phone {
    //   right: -100vw;
    //   width: 100vw;
    // }

    >.menu {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .menu-item,
    .menu-item:visited {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-family: $font-hind;
      font-weight: 300;
      font-size: rem(18px);
      line-height: 1;
      text-transform: uppercase;
      color: $black;
      padding: 0 rem(20px);

      &:hover,
      &:focus {
        color: lighten($black, 10%);
      }

      @include tablet-max {
        display: none!important;
      }

      .covid-19-updates {
        color: $red;
        // box-shadow: inset 0px 0px 0px 2px rgba($red,1);
        border-bottom: rem(2px) dashed $red;

        &.active,
        &:hover,
        &:focus {
          color: $red;
        }
      }
    }

    .nav-search-toggle {
      width: $nav-bar-toggle-width;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-search {
        font-size: rem(26px);
      }
    }

    .nav-apply {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-family: $font-hind;
      font-size: rem(18px);
      font-weight: 900;
      text-transform: uppercase;
      background-color: $purple;
      color: $white;
      padding: 0 rem(18px) 0 rem(28px);

    }
  }

  .nav-dropdown {
    position: fixed;
    top: calc(-100vh - #{$nav-bar-height});
    left: 0;
    right: 0;
    height: calc(100vh - #{$nav-bar-height});
    transition: $base-transition;
    background-color: $white;
    // padding: rem(20px) rem(34px);
    overflow-x: hidden;
    overflow-y: auto;

    // @include tablet {
    //   padding: rem(30px) rem(44px);
    // }
    //
    // @include lg-tablet {
    //   padding: rem(40px) rem(84px) rem(70px) rem(54px);
    // }

    > .row {

      @include lg-tablet-max {
        flex-wrap: wrap;

        &::after {
          content: '';
          flex: auto;
        }
      }
    }

    .col {
      display: flex;
      flex-direction: column;

    }

    .menu-title {
      font-weight: 700;
      font-size: rem(13px);
      text-transform: uppercase;
      margin-top: rem(44px);
      margin-bottom: rem(40px);
      color: $red;

      &.blank {
        @include phone {
          display: none;
        }
      }
    }

    .search-menu {
      padding: rem(35px) rem(55px) 0 rem(70px);
      flex-basis: 75%;
      max-width: 75%;

      @include lg-tablet-max {
        flex-basis: 100%;
        max-width: 100%;
      }

      @include phone {
        padding: rem(35px) rem(50px) 0;
      }
    }

    .menu {
      @include lg-tablet-max {
        flex-basis: 33.3333%;
        max-width: 33.3333%;
        margin-bottom: rem(40px);
      }

      @include phone {
        flex-basis: 100%;
        max-width: 100%;

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }

      &.quick {
        padding-left: rem(50px);

        @include phone {
          .menu-title {
            margin-top: rem(20px);
          }
        }

        &:before {
          @include pseudo;
          top: rem(105px);
          left: 0;
          bottom: 0;
          width: rem(1px);
          background-color: $red;

          @include phone {
            top: rem(80px);
            bottom: rem(10px);
          }
        }
      }

      .menu-item,
      .menu-item:visited {
        font-weight: 300;
        font-size: rem(24px);
        color: $black;

        &:not(:last-child) {
          margin-bottom: rem(10px);
        }

        @include phone {
          font-size: rem(16px);
          margin-bottom: rem(10px);
          // margin-bottom: rem(12px);
        }

        // &.hover {
        //
        // }

        &.covid-19-updates {
          color: $red;

          @include lg-tablet {
            display: none!important;
          }

          &.active,
          &:hover,
          &:focus {
            color: $red;
          }
        }
      }
    }

    .col.contact {
      flex-basis: 25%;
      max-width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $red;

      @include lg-tablet-max {
        flex-basis: 100%;
        max-width: 100%;
        padding: rem(40px) 0;
      }

      // @include phone {
      //   flex-basis: 100%;
      //   max-width: 100%;
      // }

      .contact-container {
        width: rem(250px);
        margin-right: rem(20px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      .nav-heading {
        font-weight: 700;
        font-size: rem(16px);
        line-height: rem(23px);
        margin-bottom: 0;
        color: $white;
        text-transform: uppercase;
      }

      .nav-address {
        font-weight: 400;
        font-size: rem(16px);
        line-height: rem(23px);
        margin-bottom: 0;
        color: $white;
      }

      .nav-subheading {
        font-weight: 300;
        font-size: rem(16px);
        line-height: rem(22px);
        margin-top: rem(22px);
        margin-bottom: 0;
      }

      .nav-contact-link,
      .nav-contact-link:visited {
        font-weight: 600;
        font-size: rem(16px);
        line-height: rem(23px);
        color: $white;
        margin-bottom: 0;

        &.phone {
          margin-top: rem(30px);
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .nav-cta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: rem(45px);

        @include phone {
          margin-top: rem(32px);
        }

        .btn-link {
          margin-top: 0;
        }

        .btn-link.blue + .btn-link.yellow {
          margin-top: rem(25px);
        }
      }

      .nav-social {
        margin-top: rem(40px);
        width: 100%;
        display: flex;
        justify-content: space-between;

        .nav-social-link,
        .nav-social-link:visited {
          border-radius: 50%;
          width: rem(36px);
          height: rem(36px);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: $white;

          // &:not(:last-child) {
          //   margin-right: rem(32px);
          //
          //   @include laptop {
          //     margin-right: rem(17px);
          //   }
          //
          //   @include desktop {
          //     margin-right: rem(32px);
          //   }
          // }

          &:hover {
            background-color: darken($white, 10%);
          }

          [class^="icon-"]:before,
          [class*=" icon-"]:before {
            color: $red;
            font-size: rem(24px);
            line-height: rem(36px);
          }
        }
      }
    }
  }
}

body {
  &.nav-open {

    nav {
      top: 0!important;
    }

    .nav-dropdown {
      top: $nav-bar-height;
    }

    .nav-toggle {
      .nav-toggle-icon {
        background-color: $off-white;

        &:before {
          top: 0;
          transform: rotate(-45deg);
          background-color: $red;
        }

        &:after {
          bottom: 0;
          transform: rotate(45deg);
          background-color: $red;
        }
      }
    }
  }
}

.gallery {

  h2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    margin-bottom: rem(40px);
  }

  .gallery-container {

    @include container {
      padding: 0 rem(100px);
    }
  }

  .gallery-images {
    margin-left: auto;
    margin-right: auto;
  }

  .gallery-images,
  .slick-slide {
    width: rem(885px);
    max-width: rem(885px);
    height: rem(525px);
    max-height: rem(525px);

    @include container {
      width: 100%;
      max-width: 100%;
      height: calc((100vw - 240px) * (525 / 885));
      max-height: calc((100vw - 240px) * (525 / 885));
    }
  }

  .gallery-image {
    width: rem(885px);
    max-width: rem(885px);
    height: rem(525px);
    max-height: rem(525px);
    display: flex;
    align-items: center;
    justify-content: center;

    @include container {
      width: 100%;
      max-width: 100%;
      height: calc((100vw - 240px) * (525 / 885));
      max-height: calc((100vw - 240px) * (525 / 885));
    }
  }

  .icon-linkarrow {
    transition: $base-transition;

    &:hover,
    &:active,
    &:focus {
      background-color: lighten($dark-blue, 10%);
    }
  }

  .gallery-images-index {
    margin: rem(10px) auto 0;
    font-family: $font-hind;
    font-weight: 700;
    font-size: rem(24px);
    color: rgba($dark-blue, 0.5);
    line-height: 1;
    text-align: center;

    #gallery-images-index {
      font-size: rem(53px);
      color: $dark-blue;
    }

    .slash {
      font-size: rem(22px);
    }
  }
}

$phone:        rem($phone-px);
$tablet:       rem($tablet-px);
$lg-tablet:    rem($lg-tablet-px);
$laptop:       rem($laptop-px);
$desktop:      rem($desktop-px);

// eg @include from($tablet) { @include until($desktop) { ... } }
// eg @include tablet { @include laptop-max { ... } }
// eg @include lg-tablet { @include portrait { ... } }
@mixin from($device: $phone) {
  @media only screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device: $desktop) {
  @media only screen and (max-width: ($device - rem(1px))) {
    @content;
  }
}

@mixin portrait {
  @media only screen and (orientation: portrait) {
    @content;
  }
}

@mixin landscape {
  @media only screen and (orientation: landscape) {
    @content;
  }
}

@mixin phone {
  @media only screen and (max-width: ($tablet - rem(1px))) {
    @content;
  }
}

@mixin phone-max {
  @media only screen and (max-width: ($tablet - rem(1px))) {
    @content;
  }
}

@mixin phone-only {
  @media only screen and (max-width: ($tablet - rem(1px))) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: $tablet) {
    @content;
  }
}

@mixin tablet-max {
  @media only screen and (max-width: ($lg-tablet - rem(1px))) {
    @content;
  }
}

@mixin tablet-only {
  @media only screen and (min-width: $tablet) and (max-width: ($lg-tablet - rem(1px))) {
    @content;
  }
}

@mixin lg-tablet {
  @media only screen and (min-width: $lg-tablet) {
    @content;
  }
}

@mixin lg-tablet-max {
  @media only screen and (max-width: ($laptop - rem(1px))) {
    @content;
  }
}

@mixin lg-tablet-only {
  @media only screen and (min-width: $lg-tablet) and (max-width: ($laptop - rem(1px))) {
    @content;
  }
}

@mixin tablets-only {
  @media only screen and (min-width: $tablet) and (max-width: ($laptop - rem(1px))) {
    @content;
  }
}

@mixin touch {
  @media only screen and (max-width: ($laptop - rem(1px))) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (min-width: $laptop) {
    @content;
  }
}

@mixin laptop-max {
  @media only screen and (max-width: ($desktop - rem(1px))) {
    @content;
  }
}

@mixin laptop-only {
  @media only screen and (min-width: $laptop) and (max-width: ($desktop - rem(1px))) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-max {
  @media only screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media only screen and (min-width: $desktop) {
    @content;
  }
}

@mixin container {
  @media only screen and (max-width: ($container-max-width - rem(1px))) {
    @content;
  }
}

@mixin container-xs {
  @media only screen and (max-width: ($container-max-width-xs - rem(1px))) {
    @content;
  }
}

.carousel {
  @include full-background;

  h2 {
    text-align: center;
    font-size: rem(40px);
  }

  .carousel-slides {
    padding-left: rem(90px);

    @include phone {
      padding-left: rem(33px);
    }

    .slick-track {
      display: flex;

      .slick-slide {
        height: 100%;

        > div {
          height: 100%;
        }

        .slide {
          padding: 0 rem(15px) rem(60px) rem(15px);
          display: flex;
          align-items: stretch;
          height: 100%;

          @include phone {
            padding: 0 0 rem(40px) rem(45px);
          }

          .slide-container {
            // box-shadow: -45px 60px 0px 0px $off-white;
            height: 100%;
            min-height: rem(480px);
            display: flex;
            flex-direction: column;
            background-color: $dark-blue;

            .text-container {
              background-color: $dark-blue;
              padding: rem(25px) rem(30px) rem(50px);
              flex: 1;
              position: relative;
            }

            h4 {
              margin-bottom: rem(20px);
              padding-right: rem(30px);
              color: $white;
            }

            p {
              color: $white;
              font-weight: 300;
              font-size: rem(18px);
              margin-bottom: 0;
            }

            .btn-link {
              position: absolute;
              bottom: rem(-25px);
              right: rem(25px);
              margin: 0;
            }
          }
        }
      }
    }
  }

  .carousel-index {
    margin: rem(20px) auto 0;
    font-weight: 400;
    font-size: rem(24px);
    // color: rgba($dark-blue, 0.5);
    line-height: 1;
    text-align: center;

    #carousel-index {
      font-weight: 700;
      // color: $dark-blue;
    }

    .slash {
      font-size: rem(22px);
    }
  }

  .slick-prev {
    left: 0;
    transform: translateY(rem(-29px));
  }

  .slick-next {
    left: 0;
    transform: translateY(rem(29px));
  }
}

.header-news {

  .filter {
    margin: rem(20px) 0 rem(80px);
    display: flex;
    border-bottom: rem(1px) solid $light-grey;

    @include container {
      flex-direction: column;
      display: inline-flex;
    }

    p {
      font-weight: 300;
      font-size: rem(21px);
      line-height: rem(42px);
      color: $light-grey2;
      margin-right: rem(30px);
      margin-bottom: 0;
      white-space: nowrap;
    }

    a,
    a:visited {
      transition: $base-transition;
      background-color: $white;
      font-weight: 300;
      font-size: rem(21px);
      line-height: rem(42px);
      padding: 0 rem(20px);
      margin: 0 rem(5px);
      white-space: nowrap;

      @include container {
        margin: 0;
      }

      &.active,
      &:hover,
      &:focus {
        background-color: $red;
        color: $white;
      }
    }
  }
}

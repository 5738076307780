form {
  @extend %row;
  @extend %row-wrap;

  .field {
    margin-top: rem(25px);

    @include phone {
      flex-basis: 100%;
      max-width: 100%;
    }

    &.urgent {
      label {
        color: $red;
      }
    }

    &.red {
      label {
        &:not(.field-checkbox):not(.field-radio) {
          font-weight: 400;
          font-size: rem(14px);
          color: $red;
        }

        &.field-checkbox,
        &.field-radio {
          font-weight: 400;
          font-size: rem(14px);
        }
      }
    }

    &.red-label {

      .field-checkbox,
      .field-radio {
        font-weight: 400;
        font-size: rem(14px);
        color: $red;
      }
    }

    &.sub-label {

      label {
        span {
          font-weight: 400;
          font-size: rem(14px);
        }

        &.required {

          &:after {
            content: '';
          }

          span:before {
            content: '* ';
            font-weight: 700;
            font-size: rem(18px);
          }
        }
      }
    }

  }

  label {
    // font-family: $font-hind;
    font-weight: 700;
    font-size: rem(18px);
    line-height: rem(20px);
    // letter-spacing: rem(1px);

    // @include phone {
    //   font-size: rem(18px);
    //   line-height: 1.4;
    // }
    //
    // &.blank-label {
    //   @include phone {
    //     display: none;
    //   }
    // }

    &.required {

      &:after {
        content: ' *';
      }
    }
  }

  [type="color"],
  [type="date"],
  [type="datetime"],
  [type="datetime-local"],
  [type="email"],
  [type="month"],
  [type="number"],
  [type="password"],
  // [type="search"],
  [type="tel"],
  [type="text"],
  [type="time"],
  [type="url"],
  [type="week"],
  input:not([type]),
  select {
    height:  rem(54px);
    padding: rem(5px) rem(20px);
    font-weight: 500;
    font-size: rem(21px);
    background-color: $white;
    border-radius: 0;
    border: rem(1px) solid $light-grey2;

    @include phone {
      height:  rem(34px);
      padding: rem(5px) rem(10px);
      font-size: rem(18px);
    }

    &:focus {
      background-color: $white;
      border-radius: 0;
      border: rem(1px) solid $yellow;
    }

    @include placeholder {
      font-weight: 500;
      font-size: rem(21px);
      color: $light-grey2;

      @include phone {
        font-size: rem(18px);
      }
    }
  }

  textarea {
    padding: rem(12px) rem(20px);
    font-weight: 500;
    font-size: rem(22.5px);
    background-color: $white;
    border-radius: 0;
    border: none;
    min-height: rem(150px);
    border: rem(1px) solid $light-grey2;

    @include phone {
      padding: rem(8px) rem(10px);
      font-size: rem(18px);
    }

    &:focus {
      background-color: $white;
      border-radius: 0;
      border: rem(1px) solid $yellow;
    }

    @include placeholder {
      font-weight: 500;
      font-size: rem(22.5px);
      color: $grey;

      @include phone {
        font-size: rem(18px);
      }
    }
  }

  .field-select {
    position: relative;

    &:before {
      @include pseudo($content: '>');
      left: rem(35px);
      top: rem(48px);
      font-weight: 500;
      font-size: rem(21px);
    }

    select {
      padding: rem(10px) rem(20px) rem(10px) rem(40px);

      @include phone {
        padding: rem(5px) rem(10px) rem(5px) rem(40px);
      }

      option {
        background-color: $white;
        padding: rem(10px) rem(20px);

        @include phone {
          padding: rem(5px) rem(10px);
        }

        &[selected] {
          background-color: darken($white, 10%);
        }
      }
    }
  }

  .field-checkboxes {

    &.no-label {
      display: flex;
      flex-wrap: wrap;

      .field-checkbox {
        flex-basis: 50%;
        max-width: 50%;

        @include phone {
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      @include phone {
        overflow-y: scroll;
        max-height: rem(265px);
      }
    }

    .field-checkbox {
      display: block;
      position: relative;
      padding-left: rem(45px);
      margin-bottom: rem(12px);
      cursor: pointer;
      font-size: rem(17px);
      line-height: rem(29px);
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark:after {
          opacity: 1;
        }
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: rem(19px);
        width: rem(19px);
        background-color: $white;
        border: rem(1px) solid $light-grey2;


        &:after {
          content: '';
          position: absolute;
          left: rem(5px);
          top: rem(2px);
          width: rem(6px);
          height: rem(12px);
          border: solid $grey;
          border-width: 0 rem(3px) rem(3px) 0;
          transform: rotate(45deg);
          transition: $base-transition;
          opacity: 0;
        }
      }

      &:hover,
      &:focus {
        input:not(:checked) ~ .checkmark:after {
          opacity: 0.5;
        }
      }
    }
  }

  .field-radios {


    .field-radio {
      display: block;
      position: relative;
      padding-left: rem(45px);
      margin-bottom: rem(12px);
      cursor: pointer;
      font-size: rem(17px);
      line-height: rem(29px);
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkoption:after {
          opacity: 1;
        }
      }

      .checkoption {
        position: absolute;
        top: 0;
        left: 0;
        height: rem(19px);
        width: rem(19px);
        background-color: $white;
        border-radius: 50%;
        border: rem(1px) solid $light-grey2;

        &:after {
          content: '';
          position: absolute;
          top: rem(3px);
          left: rem(3px);
          width: rem(11px);
          height: rem(11px);
          border-radius: 50%;
          background: $grey;
          transition: $base-transition;
          opacity: 0;
        }
      }

      &:hover,
      &:focus {
        input:not(:checked) ~ .checkoption:after {
          opacity: 0.5;
        }
      }
    }
  }

  .label-small {
    > label {
      font-size: rem(17px);
      line-height: rem(26px);
    }
  }

  .gap {
    > label:not(.field-checkbox) {
      margin-top: rem(40px);
      margin-bottom: rem(20px);
    }
  }

  .radio-statement {

    &:after {
      content: 'Please select one course';
      display: block;
      font-weight: 500;
      font-style: italic;
      font-size: rem(15px);
      margin-top: rem(20px);
    }
  }

  .g-recaptcha-statement {
    margin-top: rem(20px);
  }

  .submit {
    margin-top: rem(5px);

    &:not(.left) {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      // .btn-link {
      //   margin-left: auto;
      // }
    }
  }



  // .btn-submit {
  //   margin-top: rem(50px);
  //   margin-left: auto;
  //   margin-right: auto;
  //   display: block;
  //   height: rem(50px);
  //   line-height: rem(50px);
  //   background-color: $gold;
  //   padding: 0 rem(22px);
  //   font-family: $font-hind;
  //   font-weight: 600;
  //   font-size: rem(16px);
  //   text-transform: uppercase;
  //   color: $dark-blue;
  //   border: rem(1px) solid $white;
  //   border-radius: rem(25px);
  //
  //   // @include phone {
  //   //   height: rem(50px);
  //   //   line-height: rem(50px);
  //   //   padding: 0 rem(48px);
  //   //   font-size: rem(16px);
  //   // }
  //
  //   &:hover {
  //     background-color: lighten($gold, 10%);
  //     color: $dark-blue;
  //   }
  // }
}

// .table-entry {
//
//   label {
//     background-color: $blue;
//     color: $white;
//     padding: 0 $grid-gutter;
//     line-height: rem(54px);
//     font-weight: 500;
//     font-size: rem(17px);
//     width: 100%;
//     margin: 0 0 rem(10px);
//
//     @include lg-tablet {
//       display: none;
//       padding: 0 #{rem(10px)};
//     }
//   }
//
//   &.top {
//     @include lg-tablet {
//       margin-top: rem(10px)!important;
//     }
//
//     label {
//       display: block;
//     }
//   }
//
//   &.col-subject,
//   &.col-year,
//   &.col-predicted,
//   &.col-actual,
//   &.col-plus {
//     flex: 1 0 0;
//     min-width: 0;
//     margin: 0;
//     padding: 0 $grid-gutter;
//     position: relative;
//     display: inline-flex;
//     flex-direction: column;
//     align-items: flex-end;
//     justify-content: flex-end;
//
//     @include lg-tablet {
//       padding: 0;
//     }
//
//     input {
//       margin-bottom: rem(10px);
//
//       @include lg-tablet {
//         width: calc(100% - #{rem(10px)});
//       }
//     }
//
//     @include tablet-max {
//       flex-basis: 100%!important;
//       max-width: 100%!important;
//     }
//   }
//
//   &.col-subject {
//     flex-basis: 32.5%;
//     max-width: 32.5%;
//     padding-left: $grid-gutter;
//
//     @include tablet-max {
//       padding-top: rem(11px);
//
//       &:not(.top):before {
//         @include pseudo;
//         left: $grid-gutter;
//         right: $grid-gutter;
//         top: 0;
//         height: rem(1px);
//         background: $purple-dark-blue;
//       }
//     }
//
//     input {
//       width: 100%;
//     }
//   }
//
//   &.col-year {
//     flex-basis: 12.5%;
//     max-width: 12.5%;
//   }
//
//   &.col-predicted {
//     flex-basis: 25%;
//     max-width: 25%;
//   }
//
//   &.col-actual {
//     flex-basis: 25%;
//     max-width: 25%;
//     padding-right: rem(10px);
//
//     @include tablet-max {
//       padding-right: $grid-gutter;
//     }
//   }
//
//   &.col-plus {
//     flex-basis: 5%;
//     max-width: 5%;
//     padding: 0 $grid-gutter;
//
//
//     .btn-plus {
//       width: rem(29px);
//       height: rem(29px);
//       display: inline-flex;
//       align-items: center;
//       justify-content: center;
//       padding: 0;
//       border-radius: 50%;
//       border: rem(1px) solid $dark-blue;
//       color: $dark-blue;
//       font-weight: 500;
//       font-size: rem(25px);
//       line-height: 1;
//       text-align: center;
//       margin-bottom: rem(22px);
//
//       @include tablet-max {
//         margin: $grid-gutter $grid-gutter 0 0;
//       }
//     }
//   }
//
//   &.row-2,
//   &.row-3,
//   &.row-4,
//   &.row-5,
//   &.row-6,
//   &.row-7,
//   &.row-8,
//   &.row-9,
//   &.row-10,
//   &.row-11,
//   &.row-12 {
//     display: none;
//   }
//
//   &.show {
//
//     &.col-subject,
//     &.col-year,
//     &.col-predicted,
//     &.col-actual,
//     &.col-plus {
//       display: inline-flex;
//     }
//   }
// }

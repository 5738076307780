.video {

  .video-wrapper {
    position: relative;
    width: calc(100vw - ((100vw - #{$container-max-width-px}) / 2));
    @include full-background;
    @include responsive-ratio(1920, 1080, true);

    @include container {
      width: 100vw;
    }
  }
}

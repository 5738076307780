.contact {
  padding-bottom: 0;

  .wrapper {
    background-color: $off-white;
    width: calc(100vw - ((100vw - #{$container-max-width-px}) / 2));

    @include container {
      width: 100vw;
    }

    .container{
      // margin-left: calc(100vw - ((100vw - #{$container-max-width-px}) / 2));
      margin-right: 0;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  .red-box {
    flex-basis: 40%;
    max-width: 40%;
    background-image: url('../images/RedBackground.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: rem(50px) rem(90px) rem(90px) rem(50px);
    margin-top: rem(-50px);
    display: flex;
    flex-direction: column;

    @include container {
      flex-basis: 50%;
      max-width: 50%;
    }

    @include phone {
      flex-basis: 100%;
      max-width: 100%;
    }

    .nav-heading {
      font-weight: 700;
      font-size: rem(16px);
      line-height: rem(23px);
      margin-bottom: 0;
      color: $white;
      text-transform: uppercase;
    }

    .nav-address {
      font-weight: 400;
      font-size: rem(16px);
      line-height: rem(23px);
      margin-bottom: 0;
      color: $white;
    }

    .nav-subheading {
      font-weight: 300;
      font-size: rem(16px);
      line-height: rem(22px);
      margin-top: rem(22px);
      margin-bottom: 0;
    }

    .nav-contact-link,
    .nav-contact-link:visited {
      font-weight: 600;
      font-size: rem(16px);
      line-height: rem(23px);
      color: $white;
      margin-bottom: 0;

      &.phone {
        margin-top: rem(30px);
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .nav-cta {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: rem(45px);

      @include phone {
        margin-top: rem(32px);
      }

      .btn-link {
        margin-top: 0;
      }

      .btn-link.blue + .btn-link.yellow {
        margin-top: rem(25px);
      }
    }
  }

  .contact-form {
    padding: rem(60px) rem(40px) rem(60px) rem(60px);
    z-index: 1;

    @include container {
      flex-basis: 100%!important;
      max-width: 100%!important;
      margin-top: rem(20px);
    }

    h2 {
      font-size: rem(40px);
      // color: $gold;
      // text-align: center;
    }

    // p {
    //   color: $white;
    //   text-align: center;
    // }
  }

  .map-image {
    margin-top: rem(65px);
    // @include tablet {
    //   margin-top: rem(-130px);
    // }

    @include full-background;
    @include responsive-ratio(1200, 480, true);
  }
}

.values {
  padding-top: rem(105px);

  .wrapper {
    background-color: $off-white;
    width: calc(100vw - ((100vw - #{$container-max-width-px}) / 2));

    @include container {
      width: 100vw;
    }

    .container {
      // margin-left: calc(100vw - ((100vw - #{$container-max-width-px}) / 2));
      margin-right: 0;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  .list-box {
    flex-basis: 33%;
    max-width: 33%;
    background-image: url('../images/RedBackground.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: rem(35px);
    margin-top: rem(-50px);

    @include phone {
      flex-basis: 100%;
      max-width: 100%;
    }

    h4 {
      font-size: rem(18px);
      text-transform: uppercase;
      color: $yellow;
      margin-bottom: rem(30px);
    }

    ul {
      list-style-type: disc;
      list-style-position: inside;

      li {
        font-size: rem(32px);
        color: $white;
        margin-bottom: rem(30px);

        @include container {
          font-size: rem(21px);
          margin-bottom: rem(26px);
        }
      }
    }
  }

  .text-container {
    flex-basis: 67%;
    max-width: 67%;
    padding: rem(40px) rem(30px) rem(40px) rem(55px);

    @include phone {
      flex-basis: 100%;
      max-width: 100%;
      padding: rem(40px) $container-padding-h;
    }

    h2 {
      font-size: rem(18px);
      text-transform: uppercase;
    }

    p {
      font-size: rem(34px);
      margin-bottom: rem(30px);

      @include container {
        font-size: rem(28px);
        margin-bottom: rem(22px);
      }

      @include phone {
        font-size: rem(21px);
        margin-bottom: rem(18px);
      }
    }
  }
}

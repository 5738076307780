figure {
  margin: 0;
}

img,
picture {
  margin: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
}

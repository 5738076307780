.full-image {
  padding: 0;

  .image {
    position: relative;
    width: calc(100vw - ((100vw - #{$container-max-width-px}) / 2));
    @include full-background;
    @include responsive-ratio(1100, 565, true);

    &.right {
      margin-left: auto;
    }

    @include container {
      width: 100vw;
    }
  }

  +.full-image {
    padding-top: rem(60px);
  }
}

.header-slider {
  // background-color: $dark-blue;
  position: relative;
  width: calc(100vw - ((100vw - #{$container-max-width-px}) / 2) - #{rem(29px)});
  // @include full-background;
  // @include responsive-ratio(1100, 565, true);

  @include container {
    width: 100vw;
  }

  .slick-prev {
    left: auto!important;
    top: auto;
    right: rem(-29px);
    bottom: 0!important;
    transform: translateY(rem(-58px));
    z-index: 1;

    @include container {
      right: 0;
    }
  }

  .slick-next {
    left: auto!important;
    top: auto;
    right: rem(-29px)!important;
    bottom: 0!important;
    transform: translateY(0);
    z-index: 1;

    @include container {
      right: 0!important;
    }
  }

  .slick-slide {
    // height: auto;
    // @include responsive-ratio(1100, 565);
    // max-height: calc(100vw * (565 / 1100));

    // @include landscape {
    //   height: 100vh;
    //   max-height: 100vh;
    // }

    // @include portrait {
    //   height: auto;
    //   @include responsive-ratio(1200, 750);
    // }
    // max-height: calc((100vw - (#{$container-max-width-px} / 2)) * (565 / 1100));
    // overflow: hidden;

    >div {
      // @include responsive-ratio(1100, 565);
      max-height: calc((100vw - (#{$container-max-width-px} / 2)) * (565 / 1100));
      // overflow: hidden;

      @include container {
        max-height: calc(100vw * (565 / 1100));
      }

      // @include landscape {
      //   height: 100vh;
      //   max-height: 100vh;
      // }
      //
      // @include portrait {
      // //   @include responsive-ratio(1200, 750);
      //     max-height: calc(100vw * (750 / 1200));
      //
      // }
    }
  }

  // .header-slide {
  //   // max-height: calc(100vw - (#{$container-max-width-px} / 2) * (565 / 1100));
  //   // overflow: hidden;
  //   // @include responsive-ratio(1100, 565);
  //   // max-height: calc(100vw * (565 / 1100));
  //   // @include full-background;
  //
  //   // @include landscape {
  //   //   height: 100vh;
  //   //   max-height: 100vh;
  //   // }
  //
  //   // @include portrait {
  //   //   @include responsive-ratio(1100, 565);
  //   // }
  // }
}

// .header-slider-index {
//   position: absolute;
//   bottom: rem(30px);
//   left: rem(30px);
//   font-family: $font-hind;
//   font-weight: 700;
//   font-size: rem(24px);
//   color: rgba($white, 0.5);
//   line-height: 1;
//
//   #header-slider-index {
//     font-size: rem(53px);
//     color: $white;
//   }
//
//   .slash {
//     font-size: rem(22px);
//   }
// }

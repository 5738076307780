.full-image-text {
  padding: 0 0 rem(100px);

  @include phone {
    padding: 0 0 rem(250px);
  }

  .image {
    position: relative;
    width: calc(100vw - ((100vw - #{$container-max-width-px}) / 2));
    @include full-background;
    @include responsive-ratio(1100, 565, true);

    @include container {
      width: 100vw;
      max-width: 100vw;
    }

    h5 {
      font-size: rem(18px);
      line-height: rem(30px);
      height: rem(30px);
      position: absolute;
      top: rem(-30px);
      right: 0;
    }

    .text-box {
      background-color: $dark-blue;
      padding: rem(20px) rem(25px) rem(50px) rem(50px);
      width: rem(500px);
      position: absolute;
      right: 0;
      bottom: rem(-75px);
      box-shadow: rem(-3px) rem(3px) rem(5px) 0 rgba($dark-blue,0.25);

      @include phone {
        position: relative;
        right: auto;
        bottom: auto;
        top: 65vw;
        width: calc(100vw - (#{$container-padding-h} * 2));
        margin: 0 $container-padding-h;
      }

      h4 {
        font-size: rem(28px);
        color: $white;
      }

      p {
        font-weight: 300;
        font-size: rem(18px);
        line-height: rem(28px);
        color: $white;
        margin-bottom: 0
      }

      .btn-link {
        position: absolute;
        bottom: rem(-25px);
        right: rem(25px);
        margin: 0;
      }
    }
  }

}

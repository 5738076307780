.meet-the-team {

  h2 {
    text-align: center;
    font-size: rem(40px);
  }

  .paragraphs {
    padding-bottom: rem(30px);

    p {
      text-align: center;
    }
  }

  .team-slides {
    padding-left: rem(90px);

    .slick-track {
      display: flex;

      .slick-slide {
        height: 100%;

        > div {
          height: 100%;
        }

        .slide {
          padding: 0 rem(15px) rem(60px) rem(15px);
          display: flex;
          align-items: stretch;
          height: 100%;

          @include phone {
            padding: 0 0 rem(40px) rem(45px);
          }

          .slide-container {
            // box-shadow: -45px 60px 0px 0px $off-white;
            height: 100%;
            min-height: rem(400px);
            display: flex;
            flex-direction: column;
            background-color: $off-white;

            .text-container {
              background-color: $off-white;
              padding: rem(15px) rem(20px);
              flex: 1;
              position: relative;
            }

            h4 {
              margin-bottom: rem(20px);
              font-size: rem(16px);
              text-transform: uppercase;
              // color: $white;
            }

            p {
              // color: $white;
              font-weight: 300;
              font-size: rem(16px);
              margin-bottom: rem(20px);
            }

            a {
              font-weight: 700;
              font-size: rem(16px);
              color: $yellow;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .slick-prev {
    left: 0;
    transform: translateY(rem(-29px));
  }

  .slick-next {
    left: 0;
    transform: translateY(rem(29px));
  }
}

// Clearfix
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

// Pseudo element (:before, :after) defaults
@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}

// IE 10 & 11
@mixin ie() {
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    @content;
  }
}

// Include hosted font file
@mixin font-file($font-family, $font-file, $font-weight: normal, $font-style: normal) {
    @font-face {
        font-family: $font-family;
        src: url($font-file+'.eot');
        src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
        url($font-file+'.woff') format('woff'),
        url($font-file+'.woff2') format('woff2'),
        url($font-file+'.ttf') format('truetype'),
        url($font-file+'.svg#aller') format('svg');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}

// truncate tex...
@mixin truncate($truncation-max-width) {
  max-width: $truncation-max-width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Input placeholder styling
@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

@mixin fluid-type($properties, $min-value, $max-value, $min-vw: 0, $max-vw: $laptop) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media only screen and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media only screen and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

// Grid container
@mixin grid($cols: $grid-cols, $rows: $grid-rows, $gutter: $grid-gutter, $template-cols: auto, $template-rows: auto, $ie: $support-ie) {
  $grid-template-cols: $template-cols;
  $grid-template-cols-ms: $template-cols;
  @if $template-cols == auto {
    $grid-template-cols: repeat(#{$cols}, auto);
    $grid-template-cols-ms: calc(100% / #{$cols});
    @for $i from 2 through $cols {
      $grid-template-cols-ms: $grid-template-cols-ms calc(100% / #{$cols});
    }
  }

  $grid-template-rows: $template-rows;
  @if $template-rows == auto {
    $grid-template-rows: repeat(#{$rows}, auto);
  }

  display: grid;
  grid-template-columns: $grid-template-cols;
  grid-template-rows: $grid-template-rows;

  @if $gutter > 0 {
    &:not(.no-gutter) {
      grid-gap: $gutter;
    }
  }

  // IE 11
  @if $ie == true {
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      grid-template-columns: $grid-template-cols-ms;
      @if $gutter > 0 {
        &:not(.no-gutter) {
          margin-top: ($gutter * -1);
          margin-left: ($gutter * -1);
          >.grid-item {
            margin-top: $gutter;
            margin-left: $gutter;
          }
        }
      }
    }
  }

  @content;
}

// Grid item (use directly as child of grid)
@mixin grid-item($col: 1, $row: 1, $col-span: 1, $row-span: 1) {
  grid-column: $col / span $col-span;
  grid-row: $row / span $row-span;

  @content;
}

@mixin mega-nav-container {
  @media only screen and (max-width: ($container-max-width-px - 161px)) {
    @content;
  }
}

// Keep responsive aspect ratio
@mixin responsive-ratio($x, $y, $pseudo: false) {
  $rr-padding: unquote( ( $y / $x ) * 100 + '%' );
  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $rr-padding;
    }
  } @else {
    padding-top: $rr-padding;
  }
}

// Adds background cover
@mixin full-background($url: '', $pseudo: false) {
  @if $pseudo {
    position: relative;
    &:after {
      @include pseudo($pos: absolute);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @if $url != '' {
        background-image: url($url);
      }
    }
  } @else {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @if $url != '' {
      background-image: url($url);
    }
  }
}

// Full background hover
@mixin full-background-hover($url: '') {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: $base-transition;
  will-change: transform;
  @if $url != '' {
    background-image: url($url);
  }
  &:hover {
    transform-origin: center;
    transform: scale(1.1);
  }
}

// Adds background contained
@mixin contain-background($position: center, $url: '', $pseudo: false) {
  @if $pseudo {
    position: relative;
    &:after {
      @include pseudo($pos: absolute);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: $position;
      background-repeat: no-repeat;
      background-size: contain;
      @if $url != '' {
        background-image: url($url);
      }
    }
  } @else {
    background-position: $position;
    background-repeat: no-repeat;
    background-size: contain;
    @if $url != '' {
      background-image: url($url);
    }
  }
}

// Adds an overlay
@mixin overlay($color: $overlay-color, $pseudo: true) {
  @if $pseudo {
    position: relative;
    &:before {
      @include pseudo($pos: absolute);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      background-color: $color;
    }
  } @else {
    background-color: $color;
  }
}

// Adds a gradient overlay
@mixin gradient-overlay($color: $gradient-overlay, $pseudo: true) {
  @if $pseudo {
    position: relative;
    &:before {
      @include pseudo($pos: absolute);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      background: $color;
      transition: $base-transition;
    }
  } @else {
    background: $color;
    transition: $base-transition;
  }
}

// Only for screen readers
@mixin sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

// Only for screen readers & is focasable
@mixin sr-only-focusable {
    &:active,
    &:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;
    }
}

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

footer {
  background-color: $dark-grey;
  padding-top: rem(65px);

  @include phone {
    padding-top: rem(60px);
  }

  .container {
    color: $white;
  }

  // .footer-links {
  //   display: flex;
  //   align-items: flex-start;
  //
  //   @include phone-max {
  //     flex-basis: 100%!important;
  //     max-width: 100%!important;
  //   }
  //
  //   .menu {
  //     margin-right: rem(50px);
  //
  //     .menu-item {
  //
  //       &:not(:last-child) {
  //         margin-bottom: rem(35px);
  //       }
  //     }
  //   }
  //
  //   a {
  //     color: $white;
  //     font-size: rem(16px);
  //     line-height: 1;
  //     text-transform: uppercase;
  //
  //     &:hover,
  //     &:focus {
  //       color: darken($white, 10%);
  //     }
  //
  //     // &:last-child {
  //     //   @include tablet-max {
  //     //     margin-bottom: rem(30px);
  //     //   }
  //     // }
  //   }
  // }

  // .logo-social {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-end;
  //
  //   @include phone-max {
  //     flex-basis: 100%!important;
  //     max-width: 100%!important;
  //     margin-top: rem(30px);
  //     align-items: center;
  //   }
  // }

  .logo {

    @include phone {
      flex-basis: 40%!important;
      max-width: 40%!important;
    }

    .footer-logo {
      display: inline-block;
      width: rem(110px);
      // margin-bottom: rem(35px);
    }
  }

  .contact-details {
    display: flex;
    flex-direction: column;

    @include phone {
      flex-basis: 60%!important;
      max-width: 60%!important;
    }

    .nav-heading {
      font-weight: 700;
      font-size: rem(16px);
      line-height: rem(23px);
      margin-bottom: 0;
      color: $red;
      text-transform: uppercase;
    }

    .nav-address {
      font-weight: 400;
      font-size: rem(16px);
      line-height: rem(23px);
      margin-bottom: 0;
      color: $white;
    }

    .nav-subheading {
      font-weight: 300;
      font-size: rem(16px);
      line-height: rem(22px);
      margin-top: rem(22px);
      margin-bottom: 0;
    }

    .nav-contact-link,
    .nav-contact-link:visited {
      font-weight: 600;
      font-size: rem(16px);
      line-height: rem(23px);
      color: $white;
      margin-bottom: 0;

      &.phone {
        margin-top: rem(20px);
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .social-reach {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include phone {
      flex-basis: 100%!important;
      max-width: 100%!important;
      margin-top: rem(30px);
    }
  }

  .social-links {
    display: flex;
    width: rem(180px);
    justify-content: space-between;

    [class^="icon-"],
    [class*=" icon-"] {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: rem(16px);
      width: rem(26px);
      height: rem(26px);
      line-height: 1;
      border-radius: 50%;
      background-color: $red;

      // &:not(:first-child) {
      //   margin-left: rem(35px);
      // }

      &:before {
        color: $white;
        line-height: rem(16px);
      }
    }
  }

  .reach-south {
    width: rem(142px);
    margin-top: rem(20px);

    p {
      font-weight: 500;
      font-size: rem(10.66px);
      line-height: rem(14px);
      margin-top: rem(12px);
      margin-bottom: 0;
    }

    a {
      font-weight: 500;
      font-size: rem(19px);
      line-height: rem(22px);
      color: $white;

      &:after {
        content: '\e801';
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: never;
        display: inline;
        text-decoration: inherit;
        width: rem(42px);
        margin-left: rem(16px);
        text-align: center;
        font-variant: normal;
        text-transform: none;
        font-size: rem(15px);
        height: rem(22px);
        line-height: rem(22px);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }

  // .squiggle-line {
  //   margin: rem(60px) 0 rem(25px);
  //   background-image: url('../images/SquiggleLine_Centre.svg');
  //   background-position: center;
  //   background-repeat: no-repeat;
  //   background-size: auto rem(11px);
  //   height: rem(11px);
  // }

  .copyright-links {
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    font-size: rem(10px);
    line-height: 1;
    color: $white;
    margin-top: rem(15px);

    @include phone-max {
      flex-direction: column;
    }
  }

  .copyright {
    font-weight: 500;
    font-size: rem(10px);
    line-height: 1;
    color: $white;

    @include phone {
      text-align: right;
      margin-top: rem(20px);
    }
  }

  .privacy-links {
    font-weight: 500;
    font-size: rem(10px);
    line-height: 1;
    color: $white;

    @include phone-max {
      margin-top: rem(30px);
      display: flex;
      justify-content: flex-end;
    }

    a {
      font-weight: 500;
      font-size: rem(10px);
      line-height: 1;
      color: $white;

      &:hover,
      &:focus {
        color: darken($white, 10%);
        text-decoration: underline;
      }
    }
  }
}

table {
  border-collapse: collapse;
  margin: $base-spacing 0;
  text-align: left;
  width: 100%;

  &:not(.auto) {
    table-layout: fixed;
  }

  &.auto {
    table-layout: auto;
  }
}

thead {
  line-height: $heading-line-height;
  vertical-align: bottom;
}

tbody {
  vertical-align: top;
}

tr {
  border-bottom: $base-border;
}

th {
  font-weight: $heading-font-weight;
}

th,
td {
  padding: $base-small-spacing $base-small-spacing $base-small-spacing 0;
}

html,
body {
  min-height: 100vh;
  max-width: 100vw;
  background-color: $background-color;
}

header,
section,
footer {
  display: block;
}

section,
footer {
  padding: $section-padding;
}

%container {
  position: relative;
  margin: 0 auto;
  padding: $container-padding-v $container-padding-h;
  max-width: $container-max-width + ($container-padding-h * 2);
  &-xs {
    max-width: $container-max-width-xs + ($container-padding-h * 2);
  }
  &-sm {
    max-width: $container-max-width-sm + ($container-padding-h * 2);
  }
  &-md {
    max-width: $container-max-width-md + ($container-padding-h * 2);
  }
  &-lg {
    max-width: $container-max-width-lg + ($container-padding-h * 2);
  }
  &-fluid {
    max-width: 100%;
  }
}

// Import any CDN fonts
// ==================================================

// https://fonts.google.com
// https://fonts.adobe.com/typekit

// @import url("https://use.typekit.net/hoh6duq.css");
/*
Museo Sans: 100, 300, 500, 500 it, 900
Museo Sans Cond: 300
*/

@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap');


// Include any hosted fonts
// ==================================================

// @include font-file('Anamortee', '../font/anamortee', normal);

// Font stacks
// ==================================================

$font-hind: 'Hind', sans-serif; // light 300, regular 400, medium 500, semibold 600,bold 700

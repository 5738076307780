.support-ticket {

  .wrapper {
    background-color: $off-white;
    width: calc(100vw - ((100vw - #{$container-max-width-px}) / 2));
    padding-top: rem(50px);
    padding-bottom: rem(50px);

    @include container {
      width: 100vw;
    }

    .container{
      // margin-left: calc(100vw - ((100vw - #{$container-max-width-px}) / 2));
      margin-right: 0;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      padding-left: rem(20px);
      padding-right: rem(20px);

      .asset-tag {
        opacity: 0;
        overflow: hidden;
        max-height: 0;
        margin-top: 0;
        transition: $base-transition;

        &.show {
          opacity: 1;
          max-height: rem(120px);
          margin-top: rem(25px);
        }
      }
    }
  }
}

.newsletter-subscribe {
  background-image: url('../images/RedBackground.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: rem(30px);
  padding-bottom: rem(30px);

  .col-3,
  .col-5 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .col-heading {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @include tablet-max {
      flex-basis: 50%;
      max-width: 50%;
    }

    @include phone {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .col-paragraph {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @include tablet-max {
      flex-basis: 50%;
      max-width: 50%;
    }

    @include phone {
      flex-basis: 100%;
      max-width: 100%;
      margin-top: rem(30px);
    }
  }

  .col-form {
    display: flex;

    @include tablet-max {
      flex-basis: 100%;
      max-width: 100%;
      margin-top: rem(30px);
    }

    form {
      margin: 0;

       .col-4,
       .col-8 {
         margin: 0;
         padding: 0;
       }

       input {
         margin: 0;
         font-size: rem(14px);

         @include placeholder {
           font-size: rem(14px);
           color: $grey;
         }
       }

       .btn-link {
         margin: 0;
       }
    }
  }

  .newsletter-form {
    width: 100%;
    display: block;
  }

  .subscribe-fields {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: rem(15px);

  }

  .heading {
    // font-family: $font-hind;
    font-size: rem(42px);
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    margin-bottom: 0;
    white-space: nowrap;
  }

  p {
    font-size: rem(14px);
    line-height: rem(19px);
    text-align: right;
    margin-bottom: 0;
    color: $white;
    padding-left: rem(20px);
  }

  .btn-subscribe {
    margin: 0 0 0 rem(20px);

    &:after {
      content: '';
    }
  }
}

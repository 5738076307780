$form-inputs-list: (
  '[type="color"]',
  '[type="date"]',
  '[type="datetime"]',
  '[type="datetime-local"]',
  '[type="email"]',
  '[type="month"]',
  '[type="number"]',
  '[type="password"]',
  '[type="search"]',
  '[type="tel"]',
  '[type="text"]',
  '[type="time"]',
  '[type="url"]',
  '[type="week"]',
  'input:not([type])',
  'textarea'
) !default;

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 300;
  margin-bottom: $base-small-spacing / 2;
  padding: 0;
}

label {
  display: inline-block;
  font-size: rem(20px);
  line-height: rem(24px);
  font-weight: 300;
  margin-top: $base-small-spacing;
  margin-bottom: $base-small-spacing;
}

.required {
  &:not(.inline-radios) {
    label {
      &:after {
        @include pseudo($display: inline, $content: '*');
        color: $purple;
      }
    }
  }
}

#{all($form-inputs-list)} {
  appearance: none;
  background-color: $form-background-color;
  border: rem(1px) solid $grey;
  border-radius: $base-border-radius;
  box-shadow: $form-box-shadow;
  box-sizing: border-box;
  margin-bottom: $base-small-spacing;
  padding: $base-spacing / 3;
  transition: border-color $base-duration $base-timing;
  width: 100%;
  display: block;

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  &:focus {
    border: rem(1px) solid $purple;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }

  &:disabled {
    background-color: shade($form-background-color, 5%);
    cursor: not-allowed;

    &:hover {
      border: rem(1px) solid $grey;
    }
  }

  @include placeholder {
    color: tint($primary-font-color, 40%);
  }
}

#{all($form-inputs-list)},
select {
  display: block;
  font-family: $primary-font-family;
  font-size: 16px;
}

textarea {
  resize: vertical;
  min-height: rem(200px);
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: $base-small-spacing / 2;
}

[type="file"] {
  margin-bottom: $base-small-spacing;
  width: 100%;
}

select {
  margin-bottom: $base-small-spacing;
  width: 100%;
}

[type="checkbox"],
[type="radio"],
[type="file"],
select {
  &:focus {
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
    box-shadow: $focus-box-shadow;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.grecaptcha-statement {
  margin-top: rem(20px);
  color: $grey;
  font-size: rem(14px);
  line-height: rem(18px);
  font-weight: 300;
}

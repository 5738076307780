.header-text {
  padding-top: rem(50px);
  padding-bottom: rem(50px);

  &.blue {
    background-color: $dark-blue;
    border-bottom: rem(6rem) solid $red;
  }

  &.red {
    background-image: url('../images/RedBackground.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: rem(6rem) solid $dark-blue;
  }

  .container-sm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-size: rem(40px);
    text-align: center;
    color: $white;
    margin-bottom: rem(25px);
  }

  p {
    font-weight: 300;
    font-size: 18px;
    line-height: rem(28px);
    text-align: center;
    color: $white;
    margin-bottom: 0;
  }
}

h2 {
  // text-transform: uppercase;
  line-height: rem(50px);
  // letter-spacing: rem(2px);
  margin-bottom: rem(30px);

  @include phone {
    font-size: rem(36px);
    line-height: rem(42px);
  }
}

// h4 {
//   font-family: $font-hind;
//
//   &:not(.squiggle) {
//     font-weight: 700;
//     letter-spacing: rem(1px);
//     margin-bottom: rem(30px);
//   }
//
//   &.squiggle {
//     font-weight: 400;
//     font-size: rem(24px);
//     padding-left: rem(77px);
//     position: relative;
//
//     @include phone {
//       padding-left: rem(57px);
//       font-size: rem(18px);
//     }
//
//     &:before {
//       @include pseudo;
//       top: 0;
//       left: 0;
//       bottom: 0;
//       width: rem(62px);
//       background-image: url('../images/SquiggleLine_Small.svg');
//       background-position: center;
//       background-repeat: no-repeat;
//       background-size: contain;
//
//       @include phone {
//         width: rem(42px);
//       }
//     }
//   }
// }
//
// .gold {
//   color: $gold;
// }

p {
  font-size: rem(18px);
  margin-bottom: rem(10px);
}

.paragraphs {

  a,
  a:visited {
    color: $red;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  ul {
    list-style: disc;
    li {

    }
  }
}

.quote {
  // font-style: italic;
  font-weight: 600;
  font-size: rem(18px);
  color: $red;

  &:before,
  &:after {
    content: '"';
  }
}

.slick-prev,
.slick-next {
  background-color: $dark-grey;
  color: $white;
  width: rem(58px);
  height: rem(58px);
  transition: $base-transition;

  &:before {
    color: $white;
    opacity: 1;
    width: rem(58px);
    height: rem(58px);
    line-height: rem(58px);
    font-size: rem(26px);
    margin: 0;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: lighten($dark-grey, 10%);
  }

  &.slick-prev {
    left: rem(-90px);
  }

  &.slick-next {
    right: rem(-90px);
  }
}

// .blue-purple-overlay {
//   position: relative;
//
//   &:before,
//   &:after {
//     content: '';
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     width: 66vw;
//     max-width: rem(600px);
//   }
//
//   &:before {
//     left: 0;
//     background: linear-gradient(130deg, rgba($blue,0.25) 0%, rgba($blue,0) 33%, rgba($blue,0) 100%);
//   }
//
//   &:after {
//     right: 0;
//     background: linear-gradient(320deg, rgba($purple,0.25) 0%, rgba($purple,0) 33%, rgba($purple,0) 100%);
//   }
// }
//
// .gradient-border-top,
// .gradient-border {
//   position: relative;
//   &:before {
//     content: '';
//     position: absolute;
//     background: $purple-blue;
//     left: 0;
//     right: 0;
//   }
// }
//
// .gradient-border-top {
//   &:before {
//     top: 0;
//     height: rem(12px);
//   }
// }
//
// .gradient-border {
//   &:before {
//     bottom: 0;
//     height: rem(8px);
//   }
// }
//
// .line {
//   margin-top: rem(15px);
//   height: rem(1px);
//   background-color: $grey;
// }
//
// .image-sqaure {
//   @include full-background;
//   @include responsive-ratio(1, 1, true);
//
//   background-size: 100%;
//   transition: $base-transition;
//
//   &:hover {
//     background-size: 102%;
//   }
// }
//
// hr {
//   position: relative;
//   margin: rem(26px) auto;
//   max-width: rem(306px);
//   height: rem(9px);
//   background: $purple-blue;
//
//   @include phone {
//     margin: rem(21px) auto;
//   }
// }

.text-link {
  font-weight: 700;
  font-size: rem(20px);
  text-transform: uppercase;

  &:after {
    content: ' >';
  }
}

.btn-link,
.btn-link:visited {
  margin-top: rem(20px);
  display: inline-block;
  height: rem(54px);
  line-height: rem(54px);
  background-color: $dark-blue;
  // border-radius: rem(47/2);
  padding: 0 0 0 rem(18px);
  font-family: $font-hind;
  font-weight: 500;
  font-size: rem(20px);
  letter-spacing: rem(2px);
  // text-transform: uppercase;
  color: $white;
  z-index: 1;
  transition: $base-transition;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  &:after {
    content: '\e801';
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;
    display: inline-block;
    text-decoration: inherit;
    width: rem(44px);
    margin-left: rem(18px);
    text-align: center;
    font-variant: normal;
    text-transform: none;
    height: rem(32px);
    line-height: rem(32px);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-left: rem(2px) solid $white;
  }

  @include phone {
    height: rem(50px);
    line-height: rem(50px);
    padding: 0 rem(28px) 0 rem(20px);
    font-size: rem(16px);
  }

  &:hover {
    background-color: lighten($dark-blue, 10%);
    color: $white;
  }

  &.blue {
    background-color: $dark-blue;

    &:hover {
      background-color: lighten($dark-blue, 10%);
    }
  }

  &.yellow {
    background-color: $yellow;

    &:hover {
      background-color: lighten($yellow, 10%);
    }
  }

  &.red {
    background-color: $red;

    &:hover {
      background-color: lighten($red, 10%);
    }
  }
}

.cta-link,
.cta-link:visited {
  display: inline-block;
  width: rem(316px);
  height: rem(95px);
  line-height: rem(95px);
  background-image: url('../images/cta-btn.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: $font-hind;
  font-weight: 600;
  font-size: rem(26.5px);
  text-transform: uppercase;
  color: $gold;
  text-align: center;
  margin-top: rem(30px);
  margin-bottom: 0;

  &:hover,
  &:active,
  &:focus {
    color: lighten($gold, 10%);
  }
}

.mixed-styling {

  .light {
    font-weight: 300;

    +.bold,
    +.red,
    +.yellow {

      &:before {
        content: ' ';
      }
    }
  }

  .bold {
    font-weight: 700;

    +.light,
    +.red,
    +.yellow {

      &:before {
        content: ' ';
      }
    }
  }

  .red {
    font-weight: 700;
    color: $red;

    +.bold,
    +.light {

      &:before {
        content: ' ';
      }
    }
  }

  .yellow {
    font-weight: 700;
    color: $yellow;

    +.bold,
    +.light {

      &:before {
        content: ' ';
      }
    }
  }
}

.show-more-wrapper {
  position: relative;
  margin-top: rem(60px);

  // &:before {
  //   @include pseudo;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: calc(50% + #{rem(100px)});
  //   background-image: url('../images/SquiggleLine_RightSide.svg');
  //   background-position: right;
  //   background-repeat: no-repeat;
  //   background-size: auto rem(14.25px);
  // }
  //
  // &:after {
  //   @include pseudo;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: calc(50% + #{rem(100px)});
  //   background-image: url('../images/SquiggleLine_LeftSide.svg');
  //   background-position: left;
  //   background-repeat: no-repeat;
  //   background-size: auto rem(14.25px);
  // }

  .show-more,
  .show-more:visited {
    margin-top: rem(30px);
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: rem(47px);
    line-height: rem(47px);
    // background-color: $dark-blue;
    // border-radius: rem(47/2);
    // padding: 0 rem(25px);
    // font-family: $font-hind;
    font-weight: 700;
    font-size: rem(24px);
    // letter-spacing: rem(2px);
    // text-transform: uppercase;
    // color: $gold;
    z-index: 1;
    transition: $base-transition;
    white-space: nowrap;
    position: relative;

    &:after {
      transition: $base-transition;
      content: '\e804';
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: never;
      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      margin-right: .2em;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      margin-left: .2em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      position: absolute;
      bottom: rem(-15px);
      left: 50%;
      transform: translateX(-50%);
    }

    // @include phone {
    //   height: rem(50px);
    //   line-height: rem(50px);
    //   padding: 0 rem(28px) 0 rem(20px);
    //   font-size: rem(16px);
    // }

    &:hover {
      // background-color: lighten($dark-blue, 10%);
      color: lighten($primary-font-color, 10%);
      &:after {
        bottom: rem(-20px);
      }
    }
  }
}

.nav-search {
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  align-items: center;
  border-bottom: rem(1px) solid $light-grey;

  .icon-search {
    height: rem(100px);
    line-height: rem(100px);
    font-size: rem(34px);
    margin-right: rem(10px);
    color: $red;

    @include lg-tablet-max {
      height: rem(50px);
      line-height: rem(50px);
      font-size: rem(25px);
    }

    @include phone {
      height: rem(32px);
      line-height: rem(32px);
      font-size: rem(16px);
    }
  }

  .search {
    margin-bottom: 0;
    font-weight: 300;
    font-size: rem(34px);
    height: rem(100px);
    background-color: $white;
    border: none;
    box-shadow: none;

    @include placeholder {
      font-weight: 300;
      font-size: rem(34px);
      color: $light-grey2;
    }

    @include lg-tablet-max {
      font-size: rem(25px);
      height: rem(50px);

      @include placeholder {
        font-size: rem(25px);
      }
    }

    @include phone {
      font-size: rem(16px);
      height: rem(32px);

      @include placeholder {
        font-size: rem(16px);
      }
    }
  }
}

// .flickity-page-dots {
//   bottom: rem(-36px);
//
//   .dot {
//     background: $purple;
//     width: rem(17px);
//     height: rem(17px);
//     margin: 0 rem(6px);
//     opacity: 0.4;
//   }
// }

.curriculum-links {

  .curriculum-link-col {
    padding-bottom: rem(100px);

    @include container {
      flex-basis: 50%;
      max-width: 50%;
    }

    @include phone {
      flex-basis: 100%;
      max-width: 100%;
    }

    .curriculum-link {
      background-color: $light-grey;
      position: relative;
      height: 100%;
      padding-bottom: rem(20px);

      .big-heading {
        background-image: url('../images/RedBackground.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include responsive-ratio(330, 230, true);
        position: relative;

        h2 {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: rem(50px);
          text-align: center;
          color: $white;
          margin: 0;

          &.big {
            font-size: rem(90px);
          }
        }
      }

      .text-container {
        padding: rem(25px) rem(20px);
      }

      h4 {
        font-size: rem(20px);
      }

      p {
        font-weight: 300;
        font-size: rem(14px);
      }

      .btn-link {
        position: absolute;
        bottom: rem(-25px);
        right: 0;
        margin: 0;
      }
    }
  }
}

.centred-text {

  .container-sm {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      text-align: center;
      font-size: rem(40px);
    }

    .paragraphs {
      p {
        text-align: center;
        font-weight: 300;
        font-size: rem(18px)l
      }
    }

    .links {
      display: flex;
      justify-content: center;
      align-items: center;

      &.vertical {
        flex-direction: column;

        .btn-link {
          width: 100%;
        }
      }

      &.horizontal {
        @include phone {
          flex-direction: column;
        }

        .btn-link {
          @include tablet {
            width: 100%;

            &:not(:first-child) {
              margin-left: rem(25px);
            }
          }
        }
      }
    }
  }
}
